import { action, computed, observable } from 'mobx';
import { task } from 'mobx-task';
import authService from './auth/auth.service';
import { Community } from './community/community.interface';
import { Hospital } from './hospital/hospital.interface';
import { User } from './user/user.interface';
import userService from './user/user.service';
import { localStorage, sessionStorage } from './utils/storage';

export class CommonStore {
  @observable authed: boolean = !!localStorage.get('token');
  @observable user?: User;
  @observable community?: Community;
  @observable hospital?: Hospital;

  @observable screen: { width: number; height: number } = {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
  @observable domain: string = window.location.hostname.split('.')[0]; // 当前域名

  @computed get userAssociatedInfo() {
    return {
      province: this.user?.province || undefined,
      city: this.user?.city || undefined,
      county: this.user?.county || undefined,
      street: this.community?.street || undefined,
      communityId: this.user?.communityId || undefined,
    };
  }

  @task.resolved @action login = async (formData: any) => {
    const data = await authService.login(formData);
    localStorage.set('token', data.token);

    await this.init(); // 登录后进行初始化，初始化完成后再设置 authed
    this.authed = true;

    return data;
  };

  @task @action init = async () => {
    this.user = await userService.getCurrentUser();
    if (this.user.group === 'Hospital') {
      this.hospital = await userService.getCurrentHospital();
    }
  };

  @action logout = () => {
    sessionStorage.clearAll();
    localStorage.clearAll();
    window.location.replace(window.location.origin);
  };
}

const commonStore = new CommonStore();

export default commonStore;

window.onresize = () => {
  commonStore.screen = {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
};
