import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Provider } from 'mobx-react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.less';
import * as serviceWorker from './serviceWorker';
import * as stores from './stores';
import patchHistory from './utils/patchHistory';
import { localStorage } from './utils/storage';

moment.locale('zh-cn');
patchHistory();

(async function render() {
  if (localStorage.get('token')) {
    await stores.common.init();
  }
  window.CONFIG = (await fetch('/config.json').then((res) => res.json())) || {};

  ReactDOM.render(
    <Provider {...stores}>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Provider>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
  serviceWorker.unregister();
})();
