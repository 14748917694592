import { PaginationMeta } from '../common.interface';
import residentService from '../resident/resident.service';
import RESTService from '../rest.service';
import { Check } from './check.interface';

export class CheckService extends RESTService<Check> {
  list(params?: { [key: string]: any }) {
    if (params?.status === 'All') {
      return residentService.list({ ...params, status: undefined, expand: 'record' }).then(({ items, meta }) => ({
        items: items.map((item) => ({ ...item, ...item.record })),
        meta,
      })) as Promise<{
        items: Check[];
        meta: PaginationMeta;
      }>;
    }
    if (params?.status === 'Undo') {
      return residentService.list({ ...params, status: undefined, state: 'uncheck' }) as Promise<{
        items: Check[];
        meta: PaginationMeta;
      }>;
    }
    const expand = ['community'].join(',');
    return super.list({ expand, ...params });
  }
}

const checkService = new CheckService('/admin/record');

export default checkService;
