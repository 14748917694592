import RESTService from '../rest.service';
import { Resident } from './resident.interface';

export class ResidentService extends RESTService<Resident> {
  list(params?: { [key: string]: any }) {
    const expand = ['community', 'record', 'hospital'].filter((str) => !!str).join(',');
    return super.list({ expand, ...params });
  }
}

const residentService = new ResidentService('/admin/resident');

export default residentService;
