import { Community } from '../community/community.interface';
import RESTService from '../rest.service';
import request from '../utils/request';
import { User } from './user.interface';

const expand = ['user', 'hospital'].join(',');

export class UserService extends RESTService<User> {
  list(params?: { [key: string]: any }) {
    return super.list({ expand, ...params });
  }
  get(id: string, params?: { [key: string]: any }): Promise<User> {
    return super.get(id, { expand, ...params });
  }
  getCurrentUser(): Promise<User> {
    return request(`${this.service}/current-user`);
  }
  getCurrentHospital(): Promise<Community> {
    return request(`${this.service}/current-hospital`);
  }
  updatePassword(id: string, params: { originalPassword: string; password: string }) {
    return request(`${this.service}/${id}/password`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
}

const userService = new UserService('/admin/user');

export default userService;
