export const OSS_BUCKET = 'qpo';
export const CDN_HOST = 'https://p.qpaimg.com';
export const COMMON_SERVICE_HOST = 'https://commservice.qingpai365.com';

export const defaultPagination = {
  // hideOnSinglePage: true,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '50', '100'],
  showTotal: (total: number) => `共 ${total} 条`,
};
