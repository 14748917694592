import RESTService from '../rest.service';
import request from '../utils/request';
import { Hospital } from './hospital.interface';

const expand = ['user'].join(',');

export class HospitalService extends RESTService<Hospital> {
  list(params?: { [key: string]: any }) {
    return super.list({ expand, ...params });
  }
  get(id: string, params?: { [key: string]: any }) {
    return super.get(id, { expand, ...params });
  }
  create(params: any) {
    return request(this.service, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
    });
  }
  update(id: string, params: any) {
    return request(`${this.service}/${id}`, {
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  }
}

const hospitalService = new HospitalService('/admin/hospital');

export default hospitalService;
